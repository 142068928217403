import React, { Component } from 'react';

class SkipToContent extends Component {

    render() {
        return (
            <a href="#main-content" className='sr-only'>
                Skip To Page Content
            </a>
        )
    }
}

export default SkipToContent;