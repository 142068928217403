import React, { Component } from 'react';
import "./ContactForm.css";
import { Mail, PhoneCall } from 'react-feather';
import { send } from 'emailjs-com';

class ContactForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            "formSubmitterName": "",
            "formSubmitterContact": "",
            "formSubmitterMessage": "",
            "date": "",
            emailSent: false
        }
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(e) {
        this.setState({ [e.target.name]: e.target.value, "date": Date(Date.now()).toString() })
    }

    handleSubmit(e) {
        e.preventDefault();
        let toSend = {
            formSubmitterName: this.state.formSubmitterName,
            formSubmitterContact: this.state.formSubmitterContact,
            formSubmitterMessage: this.state.formSubmitterMessage,
            date: this.state.date,
            reply_to: this.state.formSubmitterContact
        }
        send(
            process.env.REACT_APP_EMAIL_SERVICE_ID, //'SERVICEID'
            process.env.REACT_APP_EMAIL_TEMPLATE_ID, //'EMAILTEMPLATEID'
            toSend,
            process.env.REACT_APP_EMAIL_USER_ID //'UserID'
        ).then((response) => {
            console.log('SUCCESS!', response.status, response.text);
        })
            .catch((err) => {
                console.log("Failed!", err);
            })

        this.setState({
            "formSubmitterName": "",
            "formSubmitterContact": "",
            "formSubmitterMessage": "",
            "date": "",
            emailSent: true
        })
    }

    render() {
        return (
            <div className="flex justify-center">
                <form className="form-container w-full md:w-3/6 rounded-xl drop-shadow-md py-7 px-9 rounded-lg shadow-lg" onSubmit={this.handleSubmit}>
                    <h2>
                        Contact Me
                    </h2>
                    <label htmlFor='formSubmitterName'>Your Name</label>
                    <br />
                    <input value={this.state.formSubmitterName} onChange={this.handleChange}
                        type='text' id='formSubmitterName' name='formSubmitterName' className='' placeholder='Insert your name here' />
                    <br />
                    <label htmlFor='formSubmitterContact'>Your email or your phone number</label>
                    <br />
                    <input value={this.state.formSubmitterContact} onChange={this.handleChange}
                        type='text' id='formSubmitterContact' name='formSubmitterContact' className='' placeholder="Email address / Phone Number (don't forget the international prefix)" />
                    <br />
                    <label htmlFor='formSubmitterMessage'>Leave me a message</label>
                    <br />
                    <input value={this.state.formSubmitterMessage} onChange={this.handleChange}
                        type='text' id='formSubmitterMessage' name='formSubmitterMessage' className='' placeholder="Please, let me know what I can do for you." />
                    <br />
                    <button
                        className=' inline-block px-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out'
                    >Get in touch</button>
                    <div className='form-additional-info flex justify-center'>
                        <a href='tel:00447518277027' aria-label="Quick link to call Max Pace's UK number">
                            <span className='flex'><PhoneCall strokeWidth={"1px"} width={"20px"} />+44 075 1827 7027</span>
                        </a>
                        <br />
                        <a href='mailto:max@maxpace.net' aria-label='Quick link to send an email to Max Pace without using the webform'>
                            <span className='flex'><Mail strokeWidth={"1px"} width={"20px"} />max@maxpace.net</span>
                        </a>
                    </div>
                    {this.state.emailSent &&
                        <div className='text-xl min-h-[5rem] md:min-h-[3rem] text-blue-500 underline'>
                            Thanks for getting in touch!
                        </div>
                    }
                </form >
            </div >
        )
    }
}

export default ContactForm;