import './App.css';
import Homepage from './components/Homepage'

function App() {

  // "alumniOf": {
  //   "@type": "CollegeOrUniversity",
  //   "name": ["Università di Salerno", "University of Salerno"],
  // },
  // "url": {
  //   "@type": "URL",
  //   "name": "Max Pace - Website",
  //   "url": "https://maxpace.net",
  //   "keywords": [
  //     "Web Developer", "Web Development", "SEO", "Marketing", "Italy"
  //   ]
  // },

  const personSchemaOrgData = {
    "@context": "https://schema.org",
    "@type": "Person",
    "name": "Max Pace",
    "alternateName": "Massimiliano Pace",
    "email": "max@maxpace.net",
    "image": {
      "@type": "ImageObject",
      "contentUrl": "https://maxpace.net/static/media/max-face-default.b835caf49e81db10a893d9cc03045925.svg",
      "name": "Max Pace's face lineart"
    },
    "knowsLanguage": [
      {
        "@type": "Language",
        "name": "Italian",
        "alternateName": "it"
      },
      {
        "@type": "Language",
        "name": "English",
        "alternateName": "en"
      },
      {
        "@type": "Language",
        "name": "Español",
        "alternateName": "es"
      },
      {
        "@type": "Language",
        "name": "Napoletano",
        "alternateName": "it-nap"
      }
    ],
    "knowsAbout": [
      {
        "@type": "ComputerLanguage",
        "name": "JavaScript",
        "alternateName": "JS",
        "url": "https://en.wikipedia.org/wiki/JavaScript"
      },
      {
        "@type": "ComputerLanguage",
        "name": "C#",
        "alternateName": "C Sharp",
        "url": "https://en.wikipedia.org/wiki/C_Sharp_(programming_language)"
      },
      {
        "@type": "ComputerLanguage",
        "name": "HTML",
        "alternateName": "HyperText Markup Language",
        "url": "https://en.wikipedia.org/wiki/HTML"
      },
      {
        "@type": "ComputerLanguage",
        "name": "CSS",
        "alternateName": "Cascading Style Sheets",
        "url": "https://en.wikipedia.org/wiki/CSS"
      },
      "MongoDB",
      "Redis",
      "Marketing",
      "Corporate Communications",
      "SEO",
      "Video Editing",
      "Audio Editing",
      "Creative Writing"
    ]
  }

  return (
    <div className="App">
      <script type="application/ld+json">
        {JSON.stringify(personSchemaOrgData)}
      </script>
      <Homepage />
    </div>
  );
}

export default App;
