import React, { Component } from 'react';
import "./Portfolio.css";
import PortfolioLink from './PortfolioLink';

class Portfolio extends Component {

    constructor(props) {
        super(props);
        this.state = {
            what: 1
        }
        // this.changeCurrentFace = this.changeCurrentFace.bind(this);
    }

    static defaultProps = {
        what: 1
    }

    render() {
        return (
            <div className='py-4'>
                <div className='flex justify-center text-4xl font-medium leading-tight mt-[3rem] text-blue-700'>
                    <h2 className=''>Portfolio</h2>
                </div>
                <p className='hero-paragraph mx-[1rem] md:mx-[5rem] text-lg mb-[3rem] mt-[2rem]'>
                    Here are some examples of things I've built that are publicly accessible.
                </p>
                <div className='columns-4 flex justify-between md:px-[6rem]'>
                    <PortfolioLink
                        destinationLink="http://maxpace.ns0.it:8425/" linkText="Tick-It-Off"
                        pictureSrc="/assets/image/tick-it-off-logo.png" pictureAlt="Tick it Off logo"
                        githubLink="https://github.com/PsycheMax/TickItOff"
                    />
                    <PortfolioLink
                        destinationLink="https://maxpace.net/wordle-italiano/" linkText="Wordle - ITA"
                        pictureSrc="/assets/image/wordle-italiano.png" pictureAlt="Italian Wordle screenshot"
                        githubLink="https://github.com/PsycheMax/wordle-italian"
                    />
                    <PortfolioLink
                        destinationLink="http://sypace.com" linkText="SyPace.com"
                        pictureSrc="/assets/image/sypace.png" pictureAlt="SyPace.com screenshot"
                        githubLink="https://github.com/PsycheMax/SyPace-com"
                    />
                </div>
            </div >
        )
    }
}

export default Portfolio;