import React, { Component } from 'react';

class Logo extends Component {

    render() {
        return (
            <div className='m-logo-animation-class'>
                <span className='rubik-font text-9xl text-white border-0 m-8 place-content-center object-center uppercase font-bold tracking-tight'>
                    m!
                </span>
            </div>
        )
    }
}

export default Logo;