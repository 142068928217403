import React, { Component } from 'react';
import {
    Timeline,
    Events,
    UrlButton,
    ImageEvent,
    TextEvent,
    themes,
    createTheme
} from '@merc/react-timeline';

import CSharpPic from "../../img/florian-krumm-1osIUArK5oA-unsplash.jpg";
import JSPic from "../../img/james-harrison-vpOeXr5wmR4-unsplash.jpg";
import LondonPic from "../../img/pexels-photo-460672.jpg";
import ArkPic from "../../img/ark-logo.svg";

const maxTheme = createTheme(themes.default, {
    card: {
        backgroundColor: '#efefef',
    },
    date: {
        backgroundColor: '#347DC4',
    },
    marker: {
        borderColor: '#63B2FF',
    },
    timelineTrack: {
        backgroundColor: '#347DC4',
    },
});

class TimelineResume extends Component {

    constructor(props) {
        super(props);
        this.state = {
            open: false
        }
        this.toggleOpen = this.toggleOpen.bind(this);
    }

    toggleOpen() {
        this.setState(() => {
            return ({ open: !this.state.open })
        })
    }

    render() {
        return (
            <div className='flex justify-center'>
                <details className='block p-6 rounded-lg shadow-lg bg-white max-w-xl lg:max-w-3xl '>
                    <summary className='' onClick={this.toggleOpen}>
                        <h5 className='text-gray-900 text-xl leading-tight font-medium mb-2'>
                            Detailed Resume - Timeline
                        </h5>
                        <p className='text-gray-700 text-base mb-4'>Instead of using a regular CV format, I opted for a detailed timeline with my study and work experiences.</p>
                        <div className='flex justify-center '>
                            <div
                                className='inline-block px-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out'
                            >
                                {this.state.open ? 'Hide timeline' : 'Show Timeline'}</div>
                        </div>
                    </summary>
                    <div className="Timeline-container-in-details swing-in-top-fwd">
                        <Timeline theme={maxTheme}>
                            <Events>
                                <TextEvent date="January 2011" text="Started my job as a Radio Speaker and Author for Radio Avella" />
                                <TextEvent date="September 2011" text="**Bachelor's Degree** in *Media Studies and Communications*" />
                                <TextEvent date="March 2012" text="Started my job as a TV Author and Newswriter for VideoNola" />
                                <TextEvent date="July 2013" text="**Master's Degree** in *Corporate Communications and Web Marketing* - SUMMA CUM LAUDE" />
                                <div className='timeline-long-version'>
                                    <TextEvent date="December 2013" text="Started my job as Junior Account Executive for Gruppo RMB" />
                                    <TextEvent date="October 2014" text="Started my job as Account Executive and Social Media Manager for LCPromotion" />
                                    <ImageEvent
                                        date="July 2016"
                                        text="Moved to London, UK"
                                        src={LondonPic}
                                        alt="Picture of the House of Parliament, and the 'Big Ben' clock tower, in London (UK)"
                                        credit="Photo by [Pixabay](https://www.pexels.com/photo/big-ben-bridge-castle-city-460672/)"
                                    >
                                        <div>
                                            Moving from a small town in Italy to a metropolis like London was quite the cultural shock!
                                        </div>
                                    </ImageEvent>
                                    <TextEvent date="September 2016" text="Started my job as Social Media Manager for GiftyLab, a London-based Startup" />
                                    <ImageEvent
                                        date="November 2016"
                                        text="Started learning C# in my free time"
                                        src={CSharpPic}
                                        alt="C# Programming language logo"
                                        credit="Photo by [Florian Krumm](https://unsplash.com/@floriankrumm?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText)"
                                    >
                                        <div>
                                            Learning a programming language for the first time, via a Udemy course and a book, was quite the challenge!
                                        </div>
                                    </ImageEvent>
                                    <TextEvent date="January 2017" text="Started my job as Shop Sales Assistant for Pret-A-Manger, a London-based coffee and food chain" />
                                    <ImageEvent
                                        date="May 2017"
                                        text="Started learning HTML, CSS and JS - for Web Development"
                                        src={JSPic}
                                        alt="JavaScript logo"
                                        credit="Photo by [James Harrison](https://unsplash.com/@jstrippa?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText)"
                                    >
                                        <div>
                                            Having grasped some basic programming concepts with C#, I decided to move onto something else. Web Development catched my eye
                                        </div>
                                    </ImageEvent>
                                </div>
                                <ImageEvent
                                    date="December 2017 - August 2020"
                                    text="Started my job as **Digital Marketing Specialist** for **Ark Data Centres**, a UK-Based Data Centers provider"
                                    src={ArkPic}
                                    alt="Ark Data Centres logo"
                                    credit="All rights reserved [Ark Data Centres](https://arkdatacentres.co.uk)"
                                >
                                    <div>
                                        <UrlButton href="https://arkdatacentres.co.uk">
                                            At Ark I had to plan, design, and implement all the Digital Marketing (B2B) online and offline activities.
                                            Managing the launch of a new website for the company, I had a chance to make use of my newly-found programming skills.
                                        </UrlButton>
                                    </div>
                                </ImageEvent>

                                <TextEvent date="September 2020" text="Thanks the raging pandemic, I had to go back to Italy - where I started doing some freelance work in the Digital Marketing Field, while learning more about programming." />

                                <TextEvent date="Today" text="While it's good fun working as a freelance, I'm now ready to move to a more permanent role. I'm looking for a Dev position - if you think I'd be a good fit for your organization, please get in touch, I'd be thrilled to learn about it" />

                            </Events>
                        </Timeline>
                    </div>
                </details>
            </div>
        )
    }
}

export default TimelineResume;