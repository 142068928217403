import React, { Component } from 'react';
import TimelineResume from './Timeline';
import Skills from './skills/Skills'

class Resume extends Component {

    render() {
        return (
            <div className='mb-[2rem]'>
                <Skills />
                <TimelineResume />
            </div>
        )
    }
}

export default Resume;