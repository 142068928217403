import React, { Component } from 'react';
import ContactForm from './ContactForm';
import Footer from './footer/Footer';
import Header from './header/Header';
import Hero from './hero/Hero';
import Portfolio from './portfolio/Portfolio';
import Resume from './resume/Resume';

class Homepage extends Component {

    render() {
        return (
            <div>
                <div className='grid grid-cols-1 place-items-center z-10 relative'>
                    <Header />
                    <div className="wrapper mt-[5rem] justify-content mx-9 l:max-w-4xl xl:max-w-5xl 2xl:max-w-7xl">
                        <Hero />
                        <Portfolio />
                        <Resume />
                        <ContactForm />
                    </div>
                </div>
                <div className='z-0 relative pt-9 '>
                    <Footer />
                </div>
            </div>
        )
    }
}

export default Homepage;