import React, { Component } from 'react';

import GithubMark from './GitHub-Mark-64px.png';

class PortfolioLink extends Component {

    static defaultProps = {
        destinationLink: "",
        linkText: "",
        pictureSrc: "",
        pictureAlt: "",
        githubLink: ""
    }

    render() {
        return (
            <a href={this.props.destinationLink} className={"flex flex-col items-center"}>
                <img src={this.props.pictureSrc} alt={this.props.pictureAlt} className='mt-3
                    h-[7rem] md:h-[10rem] max-h-150 min-h-[75px] 
                    w-[7rem] md:w-[10rem] min-h-[75px] max-w-150
                    border-4 border-double border-blue-500 shadow-lg
                    hover:shadow-2xl hover:brightness-150 transition-all duration-200
                ' />
                <div className='font-medium text-xl md:text-2xl leading-tight text-blue-700 text-center'>{this.props.linkText}</div>
                <a href={this.props.githubLink}>
                    <img src={GithubMark} alt={"Github logo"} width={32} height={32}
                        className=' hover:shadow-2xl hover:brightness-150 transition-all duration-200 '
                    />
                </a>
            </a>
        )
    }
}

export default PortfolioLink;