import React, { Component } from 'react';
import defaultFace from '../../../svg/max-face/max-face-default.svg';
import './Face.css';

class Face extends Component {

    static defaultProps = {
        pic: defaultFace,
        defaultPic: defaultFace
    }

    render() {
        return (
            <div className='face-mouse-hover'>
                <img src={this.props.pic} alt='Max Pace face - lineart' className='z-0 max-h-[35rem] my-[70%] md:my-auto mx-auto face-fadeIn face-animated relative' />
            </div>
        )
    }
}

export default Face;