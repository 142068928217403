import React, { Component } from 'react';
import './Skills.css';
import Face from './Face'

import { PenTool, Search, Terminal, DollarSign } from 'react-feather';

import defaultFace from '../../../svg/max-face/max-face-default.svg'
import codingFace from '../../../svg/max-face/max-face-coding.svg'
import graphicDesignFace from '../../../svg/max-face/max-face-graphic-design.svg'
import learningFace from '../../../svg/max-face/max-face-learning.svg'
import marketingFace from '../../../svg/max-face/max-face-marketing.svg'

class Skills extends Component {

    constructor(props) {
        super(props);
        this.state = {
            currentFace: defaultFace
        }
        this.changeCurrentFace = this.changeCurrentFace.bind(this);
    }

    changeCurrentFace(targetStatus) {
        let newState;
        switch (targetStatus) {
            case "coding":
                newState = codingFace;
                break;
            case "graphicDesign":
                newState = graphicDesignFace;
                break;
            case "learning":
                newState = learningFace;
                break;
            case "marketing":
                newState = marketingFace;
                break;
            case "plain":
            default:
                newState = defaultFace;
                break;
        }
        this.setState((prevState) => {
            return { currentFace: newState };
        })
    }

    render() {
        return (

            <div className="mb-[5rem]">
                <div className='flex justify-center text-4xl font-medium leading-tight my-[3rem] text-blue-700'>
                    <h2 className=''>My skills</h2>
                </div>
                <div className="grid skills-container">
                    <div className="row grid grid-cols-2 ">
                        <div className="col-1 my-auto">
                            <Face pic={this.state.currentFace} defaultPic={defaultFace} />
                            <div className='flex justify-center'>
                                <div className='md:hidden hero-pic-animated hero-pic-fadeInUp text-blue-900 font-base text-xs justify-center'>(Pssst... Touch the headers on the right)</div>
                            </div>
                        </div>
                        <div className="col-1 grid grid-cols-1 skills-container-right-col">
                            <div className="row" onMouseEnter={this.changeCurrentFace.bind(this, 'coding')} onMouseLeave={this.changeCurrentFace.bind(this, 'plain')}>
                                <h3>
                                    <Terminal strokeWidth={"2px"} height={"30px"} width={"30px"} /> -
                                    Coding Skills
                                </h3>
                                <ul>
                                    <li><span className='font-bold'>Programming languages</span>: HTML, CSS, JS</li>
                                    <li><span className='font-bold'>Frontend tools</span>: Tailwind, Bootstrap, SCSS</li>
                                    <li><span className='font-bold'>Frontend frameworks</span>: React, React Native</li>
                                    <li><span className='font-bold'>Backend tools</span>: NodeJS, Express, MongoDB, MySQL, Redis, Git</li>
                                    <li><span className='font-bold'>OS</span>: Windows, Linux, Mac</li>
                                </ul>
                            </div>
                            <div className="row" onMouseEnter={this.changeCurrentFace.bind(this, 'learning')} onMouseLeave={this.changeCurrentFace.bind(this, 'plain')}>
                                <h3>
                                    <Search strokeWidth={"2px"} height={"30px"} width={"30px"} /> -
                                    Currently learning
                                </h3>
                                <ul>
                                    <li><span className='font-bold'>Tech skills and tools</span>: Rust, MySQL, Python, C# and .Net</li>
                                    <li><span className='font-bold'>Human languages</span>: 日本語</li>
                                    <li><span className='font-bold'>Creative media</span>: Pencil, Watercolors</li>
                                </ul>
                            </div>
                            <div className="row" onMouseEnter={this.changeCurrentFace.bind(this, 'marketing')} onMouseLeave={this.changeCurrentFace.bind(this, 'plain')}>
                                <h3>
                                    <DollarSign strokeWidth={"2px"} height={"30px"} width={"30px"} /> -
                                    Marketing Skills
                                </h3>
                                <ul>
                                    <li>Search Engine Optimization</li>
                                    <li>Copywriting</li>
                                    <li>Social Media Strategy</li>
                                    <li>Google Analytics configuration and data analysis</li>
                                </ul>
                            </div>
                            <div className="row" onMouseEnter={this.changeCurrentFace.bind(this, 'graphicDesign')} onMouseLeave={this.changeCurrentFace.bind(this, 'plain')}>
                                <h3>
                                    <PenTool strokeWidth={"2px"} height={"30px"} width={"30px"} /> -
                                    Graphic Design and other tools
                                </h3>
                                <ul>
                                    <li>Adobe Suite proficiency</li>
                                    <li>Video editing </li>
                                    <li>Podcast Recording and Audio Editing Skills</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Skills;