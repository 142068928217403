import React, { Component } from 'react';
import './Hero.css';
import HeroPic from './HeroPic'

class Hero extends Component {

    constructor(props) {
        super(props);
        this.state = {
            readyForAnimation: false
        }
    }

    componentDidMount() {
        this.setState(() => {
            return ({ readyForAnimation: true })
        });
    }

    render() {
        return (
            <div className='grid grid-cols-1 mx-auto'>
                <div className='row grid grid-cols-1'>
                    <div className={this.state.readyForAnimation ? '' : 'd-none'}>
                        <HeroPic />
                    </div>
                </div>
                <div id="main-content" className={this.state.readyForAnimation ? 'row md:py-auto hero-animated hero-fadeInUp' : 'row md:py-auto d-none'}>
                    <p className='hero-paragraph mx-[1rem] md:mx-[5rem] text-lg'>
                        I'm a Web Developer from Southern Italy, with a strong interest for all things Software Development. <br />
                        My job is to turn complex processes (and/or problems) into simple, intuitive and often beautiful solutions. <br />
                        I am fluent in many different languages: Italian, Napoletano, English, Español, JavaScript, HTML and CSS.<br />
                        I am also learning a bunch of other languages: C#, RUST, 日本語 (Japanese), Python.
                        <br />
                        I have been a Web Marketing Specialist, a Radio Host, a corporate Blog Writer, a Salesperson, and many other things. <br />
                        <br /><br />
                        If your team needs a versatile, adaptable developer, ready to learn new tools on the job, you're in the right place!
                    </p>
                </div>
            </div>
        )
    }
}

export default Hero;