import React, { Component } from 'react';
import './HeroPic.css';
import mainHeroPic from '../../svg/max-face/max-face-white.svg'

class HeroPic extends Component {

    render() {
        return (
            <div className=' hero-pic-container mb-[5rem]'>
                <img className='hero-pic-img object-center max-h-72 mx-auto rounded-full border-solid border-indigo-700 border bg-blue-500 stroke-white' src={mainHeroPic} alt="Max Pace's face" />
                <div className='hero-pic-text-container grid grid-cols-1 pt-8 leading-snug'>
                    <p className='hero-pic-pre-title row justify-self-center' >Hello, I'm</p>
                    <h1 className='hero-pic-title row justify-self-center tracking-tighter text-blue-500'>Max Pace</h1>
                    <p className='hero-pic-post-title row justify-self-center font-light'>a Web Developer</p>
                    <p className='hero-pic-post-title row justify-self-center text-xl hero-pic-animated hero-pic-fadeInUp font-thin text-blue-400'>(among other things)</p>
                </div>
            </div>
        )
    }
}

export default HeroPic;