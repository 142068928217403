import React, { Component } from 'react';
import SkipToContent from './SkipToContent';
import Logo from './Logo';

class Header extends Component {

    render() {
        return (
            <header className="menu-wrapper w-8/12 min-h-[150px] ">
                <nav className="border-gray-200 px-2 sm:px-4 py-2.5 rounded absolute z-10">
                    <div className="container grid grid-cols-2 md:grid-cols-3 items-center mx-auto">
                        <a href="https://maxpace.net" className="flex col-span-6 lg:col-span-2 ">
                            <Logo />
                        </a>
                        <SkipToContent />
                        <a href="https://maxpace.net">
                            <span className="hidden md:inline-block self-center text-lg font-semibold whitespace-nowrap text-white tracking-tighter">Max Pace</span>
                        </a>
                    </div>
                </nav>
                <div className="background-curve-sky w-[120%] sm:w-4/5 md:w-3/4"></div>
            </header>
        )
    }
}

export default Header;