import React, { Component } from 'react';

class Footer extends Component {

    render() {
        return (
            <footer className='bg-[#2562a050]'>
                <div className='grid grid-cols-1 justify-items-center w-full'>
                    <p className='text-center text-blue-700 p-4 z-10 '>
                        {/* I developed this website utilizing the following technology stack:
                        <br />
                        React JS -
                        Tailwind CSS -
                        MongoDB -
                        NodeJS -
                        Express -
                        Git
                        <br /> */}
                        <span>Max Pace © 2022</span>
                    </p>
                </div>
            </footer>
        )
    }
}

export default Footer;